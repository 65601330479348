.grid {
    display: grid;
    gap: 10px;
    padding: 10px;
}
.small-card-grid {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
}

@media screen and (min-width: 800px) {
    .small-card-grid {
        grid-template-columns: repeat(auto-fill, minmax(220px,1fr));
    }
    .grid {
        grid-template-columns: repeat(auto-fill, minmax(400px,1fr));
    }
}

.icon{
    object-fit: cover;
}

.row {
    display: flex;
    flex-direction: row;
}
.col ,.col-center{
    display: flex;
    flex-direction: column;
}
.col-center {align-items: center;}

.popup {
    width: 94%;
    padding:35px;
    margin: auto;
    max-width: 720px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 15px;
}

.squer{
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
}
 
.container-checked .squer {
    margin-right: -20px;
}
.container-unchecked .squer {
    margin-right: 10px;
}
 
.container-checked .check-bg {
    background-color: #ffffff;
} 

.container-checked  .search-bg {
    background-color: #f48114;
}

.search-bg svg {
    fill: #f48114;
}

.dark .container-checked .check-bg {
    background-color: #3c3f4c;
}

.container-checked  .carbonic {
    color: #ffffff;
} 

.container-checked  .carbonic-2 {
    color: #f7bcbf;
} 

.container-checked .account-check-bg  {
    background-color: #db3b54;
} 

 .account-check-bg  {
  fill: #db3b54;
} 

.checked-box{
    margin: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checked-box .check-bg{
    padding: 2px;
    display: flex;
    margin: 0 4px;
    min-width: 48px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
}
.checked-box .check-bg span{
   padding: 12px;
   border-radius: 24px;
   background-color: #ffffff;
}

.checked .check-bg{
    padding-right: 22px;
    background-color: #dd3643;
}

.resturnt-squer{
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
}
 
 
.container-checked .resturnt-squer {
    margin-top: -20px;
}
.container-unchecked .resturnt-squer {
    margin-top: 10px;
}

.container-checked .carbonic-3{
    color: #F0B9C5;
}


.topbar-logo {
    fill: #dd3643;
    margin-inline:10px ;
}
 