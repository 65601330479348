.input{
    font-family: var(--font-bold);
    padding: var(--sm);
    outline: none;
    border: none;
    color: var(--carbonic);
    font-weight: bold;
    border-radius: var(--lg);
    box-shadow:var(--shadow-lg);
    background-color: var(--carbonic-card-2) ;
}

input::placeholder {
  color: var(--carbonic-1);
  letter-spacing: 1px;
  z-index: 2;
  font-weight: bold;
  position: relative;
  font-family: var(--font-bold);
}

/* input[type="number"]:focus,input[type="text"]:focus{box-shadow: var(--shadow-focus);} */
/* input:read-only{background-color: var(--king);} */

.button{
    font-family: var(--font-bold);
    background-color: var(--prim);
    color: var(--shark);
    border-radius: 0.5rem;
    padding: 6px 16px;
    /* font-size: 12px; */
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-lg);
}

.owl-expand{
  max-height: 1800px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

#owl-reloader{
  width: 60px;
  margin-inline: auto;
  height: 80px;
  padding-top: 20px;
  z-index: -1;
  position: relative;
  
  /* display: none; */
    /* width:50px;
    margin:auto;
    height:150px;
    margin-bottom:-150px;
    position:relative;
    opacity:0;   */

}

.reload-squiggle-back {
  /* stroke-dasharray: 650;
  stroke-dashoffset: 0; */
}

.reload-squiggle {
  stroke-dasharray: 650;
  stroke-dashoffset: 0;
  filter: drop-shadow( 0px 0px 15px #e7353c);
}

.pulling{
  transition: unset;
}
.reloading {
  opacity: 1;
  transition: all 340ms linear ;
  margin-top: 0 !important;
}
.reloading .reload-squiggle  {
  animation: reload-draw 1s linear infinite;
}

.disappearing{
  transition: all 0.3s ease-in-out;
  margin-top: -80px !important;
  opacity: 0;
}


@keyframes reload {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@keyframes reload-draw {
  0% {
    stroke-dashoffset: 1950;
  }
  50% {
    stroke-dashoffset: 1300;
  }
  100% {
    stroke-dashoffset: 650;
  }
}

