@import "root.css";
@import "elements.css"; 
@import "animations.css"; 
@import "containers.css"; 

:root{
  --font-bold: "'Yekan Bakh'";
  --font-medium: "'Yekan Bakh Medium'";
  --orange :#f48114;
  --orange-1 :#ffa44e;
  --orange-2:#f9c089;
  --orange-3:#905929;
  --red :#dd3643;
}

.light {
    --carbonic-card:   #ffffff;
    --carbonic-card-1: #FBFBFB;
    --carbonic-card-2: #FFFFFF;   
    --carbonic  :#1f1d2b;
    --carbonic-1: #c3c3c6;
    --carbonic-2: #c4c4c7;
    --carbonic-3: #c4c4c7; 
    --input-card: #ffffff;
    --pack-bg: #f5f5f5;
    --active-pack :#ffffff;
    --background: #f4f4f5;
    --blur:#1f1d2b33;
    background-color: #f5f5f5;

  }

.dark {
  --carbonic-card  :  #2d303e;
  --carbonic-card-1:  #353848;
  --carbonic-card-2:  #393c4a;   
  --carbonic  :#ffffff;
  --carbonic-1 : #6c6e78;
  --carbonic-2 : #9e9fa6;
  --carbonic-3 : #767982; 
  --background: #1f1d2b;
  --input-card: #353848;
  --pack-bg: #2d303e;
  --active-pack :#3c3f4c;
  --blur:#6c6e7833;
    background-color: #1f1d2b;
}

p[state='selected']{
background-color: var(--penguin);
}
p[state='off']{
background-color: var(--goat);
}

.carbonic-card   {background-color: var(--carbonic-card);}    
.carbonic-card-1 {background-color: var(--carbonic-card-1);}  
.carbonic-card-2 {background-color: var(--carbonic-card-2);}    
.carbonic-card-8 {background-color: var(--carbonic-card-8);}  
.carbonic        {color: var(--carbonic  );}       
.carbonic-1      {color: var(--carbonic-1);}       
.carbonic-2      {color: var(--carbonic-2);}       
.carbonic-3      {color: var(--carbonic-3);}      
.pack-bg         {background-color: var(--pack-bg);}   

.bg-red         {background-color: var(--red);}       .text-red        {color: var(--red);}
.bg-orange      {background-color: var(--orange);}    .text-orange     {color: var(--orange);}
.bg-orange-1    {background-color: var(--orange-1);}  .text-orange-1   {color: var(--orange-1);}
.bg-orange-2    {background-color: var(--orange-2);}  .text-orange-2   {color: var(--orange-2);}
.bg-orange-3    {background-color: var(--orange-3);}  .text-orange-3   {color: var(--orange-3);}


