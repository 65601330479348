.items-container {
    display: grid;
    gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.items-header-container{
    padding: 10px;
}
.link{color: #2d303e;}
 
/* Cards  */
    .owl-item-builder{
        font-family: var(--font-bold);
        flex-direction: column;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 13px;
        padding: 12px;
        display: flex;
        background-color: #ffffff;    
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        overflow-x: auto;
    }
    .json-builder{
        font-family: var(--font-bold);
        flex-direction: column;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size: 13px;
        padding: 10px;
        display: flex;
        background-color: #ffffff;    
        box-shadow: 0px 0px 6px rgba(95, 20, 20, 0.16);
        max-width: calc(100% - 20px);
    }
    
    .obj{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2px 10px;
        border-radius: 0.5rem;
        background-color: #ffffff;
        margin: 4px;
        cursor: grab;
        overflow-x: auto;
    }
