.form-container{
    --prince: #2c3e50;
    --card-lord: #ffffff;
    --prince: #fff;
    --card---prince: #f9f9f9;
    
    --active:#1fd1ec;
    --light:#9ca3af;

    --rainbo: #f87171;
    --grass:#bada55;

    --shadow-lg: 0px 0px 10px rgb(45, 48, 62,0.15);
    --shadow-xl:0px 0px 6px rgba(95, 20, 20, 0.16);

    --shadow-focus: 0px 0px 6px rgba(32, 211, 238,0.5);
    --shadow-active:0px 0px 5px rgba(32, 211, 238,0.5);
    --rounded-1 : 10px;
    --rounded-2 : 14px;
    --rounded-3 : 15px;
    --rounded-4 : 20px;
    --rounded-5 : 25px;
}

/* Query Cleaner */
.owl-form-cleaner{
    flex-wrap:  wrap;
    display:  flex;
    gap: 10px;
    box-shadow: inset 0px 0px 8px #f8717155;
    border-radius:var(--rounded-2);
    margin-top: 10px;
    padding: 10px;
}

/* Container */
.form-container {
    padding: 5px;
    gap: 10px;
    margin-block:5px;
    background-color: var(--background);
    max-width: 100%;
    overflow: auto;
    /* border-radius:var(--rounded-2); */
    /* box-shadow:var(--shadow-lg); */
    /* border-bottom: var(--carbonic-2) solid 1px; */
}

/* Section */
.form-section {
    gap: 14px;    
    border-radius:var(--rounded-2);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: auto;
    padding: var(--md);
}

.clear-icon{
    color: rgb(252, 165, 165);
    font-size: 24px;
    /* background-color: var(--card-lord); */
    font-family: var(--font-bold);
    font-weight: 300;
    left: 10px;
    padding-right: 3px;
    padding-top: 2px;
    z-index: 10;
    position: absolute;
    cursor: pointer;
}
.clear-icon.hide{
    display: none;
}

.owl-toggle-button{
    display: flex;
    margin-inline: 8px;
    align-items: center;
    flex-direction: row;
    margin-block:auto;
}

.owl-toggleed-bg{
    cursor: pointer;
    padding: 2px;
    display: flex;
    margin: 0 4px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    background-color: var(--card-lord);
    box-shadow: var(--shadow-lg);
    padding-left: 22px;
}
.owl-toggleed-bg span{
    padding: 10px;
    border-radius: 24px;
    background-color: var(--card-lord);
    box-shadow: var(--shadow-lg);
}

.owl-toggleed {
    padding-right: 22px;
    padding-left: 2px;
     background-color:var(--grass); /*#F87171; */
}

/* Selector */
.owl-options-container {
    background-color: var(--carbonic-card);
    gap: 10px;
    padding:5px;
    border-radius:var(--rounded-2);
    box-shadow: var(--shadow-lg);
    overflow-x: auto;
    transition: all 200ms ease-out;
    display:grid;
    overflow-y: hidden;
    grid-template-rows: repeat(auto-fill,minmax( 35px, 0.35fr)); 
    grid-auto-flow: column;
    gap: 0.5rem;
    padding-inline:0.5rem;
}

.owl-options-container::-webkit-scrollbar { height: 4px;width: 4px;}
.owl-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;border-radius: 100px;margin: 10px;}
.owl-options-container::-webkit-scrollbar-track {background-color: #e4e4e4;}
.owl-options-container::-webkit-scrollbar-thumb {  border-radius: 6px;}
.owl-options-container:hover::-webkit-scrollbar-thumb {background-color: #3f3c4d55;}

 
.owl-options-title {
    font-family: var(--font-bold);
    font-weight: bold;
}

.owl-button-options{
    color: var(--text);
    background-color: var(--card-lord);
    border-radius:var(--rounded-2);
    padding: 10px 16px;
    text-align: center;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-lg);
    font-family: var(--font-bold);
    font-weight: bold;
    min-width: max-content;
}

.owl-selctor {
    box-shadow: var(--shadow-lg);
    border-radius:var(--rounded-2);
    padding: 0.5rem;
    outline: none;
    border: none;
    align-self: center;
    font-family: var(--font-bold);
    font-weight: bold;
}

/* Date */
.owl-label-container{
    flex-direction: row;
    align-items: center;
    max-width: 160px;
    display:  flex ;
    flex-grow: 1;
    gap: 5px;
    padding: 9px;
    border-radius:var(--rounded-2);
    background-color: var(--card-lord);
    justify-content: space-between;
    box-shadow: var(--shadow-lg);
}

.owl-label{
    color: var(--light);
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 300;
    margin-block: -20px;
    font-family: var(--font-bold);
}

.owl-date{
    outline: none;
    border: none;
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--prince);
}

/* Button */
.owl-button-conatiner{
    position: relative;
}

.owl-button{
    font-family: var(--font-bold);
    background-color: var(--card-lord);
    color: var(--rainbo);
    border-radius:var(--rounded-2);
    padding: 4px 16px;
    font-size: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: var(--shadow-lg);
}


.owl-button-conatiner{
    position: relative;
}


.selected{
    /* box-shadow: var(--shadow-active) !important; */
    background-color:var(--active) ;
    color: var(--white) !important;
}


.popup-selector-button{
    display: flex;
    flex-direction: row;
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--carbonic);
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: var(--shadow-lg);
    background-color: var(--carbonic-card);
    border-radius: 10px;
    align-items: center;
    gap: 10px;
}

.popup-selector-container{
    position: fixed;
    inset: 0;
    background-color:#2c3e5022;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(1px);
    transition: all 0.1s ease-in-out;
    animation:  blur-frames 0.1s;
    z-index: 999;
    justify-content: center;
    padding: 20px;
}

.popup-selector{
    max-width: 350px;
    width: 100%;
    margin: auto;
    background-color: var(--carbonic-card);
    color: var(--carbonic);
    border-radius:var(--rounded-2);
    box-shadow: var(--shadow-lg);
    padding: 10px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
}

.popup-selector-option{
    font-family: var(--font-bold);
    font-weight: bold;
    color: var(--carbonic);
    padding: 10px 20px;
    border-radius:var(--rounded-2);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    box-shadow: var(--shadow-lg);
    background-color: var(--carbonic-card-2);
}

.cannot-select svg{
    opacity: 0.2 !important;
}


.popup-builder{
    position: fixed;
    inset: 0;
    background-color: var(--blur) ;
    backdrop-filter: blur(4px);
    animation:  blur-frames 300ms ease-out;
    z-index: 3500;
    padding:100px 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    transition: all 0 ease-in-out;
}


.popup-child{
    gap: 10px;
    animation: popup-fade 250ms;
    color: var(--carbonic);
    border-radius:var(--4xl);
    box-shadow: var(--shadow-lg);
    margin: auto;
    display: flex;
    flex-direction: column;
    
    background-color: var(--carbonic-card);
    gap: var(--xl);
    width: 100%;
    max-width: 520px;
    padding: 20px;
    max-width: 350px;
    
}

.popup-child.invoices-popup{
    padding:unset !important;
    max-width: 500px !important;
}

@keyframes popup-fade {
    0% {
        opacity: 0;
        transform:scale(1,0.5) translateY(700px) 
    }
    70% {
        transform: translateY(-20px) skewY(-5deg);
        
    }
    100% {
        opacity: 1;
        transform:scale(1) translateY(0) skewY(0);
    }
}
.scanner{
    background-color: transparent !important;
}

@keyframes blur-frames {
    0% {
        background-color:#ccc0;
        backdrop-filter: blur(1px);
    }
    100% {
        background-color: var(--blur);
        backdrop-filter: blur(4px);
    }
}
