:root {
    transition: all 0.3s ease-in-out;
    --white:#ffffff;
    --black:#000000;

    --shadow-sm:  0px 0px 4px #5f141415;
    --shadow-md:  0px 0px 6px #5f141420;
    --shadow-lg:  0px 0px 6px #5f141430;
    --shadow-xl:  0px 0px 8px #5f141440;
    --shadow-2xl: 0px 0px 10px #5f141450;
    --shadow-3xl: 0px 0px 10px #5f141460;
  
    --shadow-focus: 0px 0px 6px rgba(32, 211, 238,0.5);
    --shadow-error: 0px 0px 6px rgba(231, 53, 60,0.5);

    --xs:3px;
    --sm:6px;
    --md:9px;
    --lg:12px;
    --xl:15px;
    --2xl:18px;
    --3xl:21px;
    --4xl:24px;
    --5xl:27px;
    --auto:auto;
}

.text-xs   {font-size: 13px;}    
.text-sm   {font-size: 14px;}    
.text-base {font-size: 15px;}  
.text-lg   {font-size: 16px;}    
.text-xl   {font-size: 18px;}    
.text-2xl  {font-size: 20px;}   
.text-3xl  {font-size: 22px;}   
.text-4xl  {font-size: 24px;}   
.text-5xl  {font-size: 26px;}   
