@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --safe-area-top: 16px;
    --safe-area-bottom: 0px;
}
.safe-area-bottom{
    padding-bottom: var(--safe-area-bottom);
}

 p,span,div,select,option {user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


body {
    height: calc(100vh - var(--safe-area-top) - var(--safe-area-bottom));
    overflow: hidden;
    scroll-behavior: smooth;
    padding-top: var(--safe-area-top);
    transition: all 200ms ease-in-out;
}
p{font-size: 15px;}
#root{
    max-width:1280px;
    margin-inline:auto;
    overflow: hidden;
    position: relative;
}

.wrapper{
    height: calc(100vh - var(--safe-area-top) - var(--safe-area-bottom));
    overflow: auto;
    scroll-behavior: smooth;
}

.primary-text{
    font-family: "'Yekan Bakh'";
    font-weight: 400;
}
.selector{
    flex-grow: 1;
    min-width:150px;
    max-width: 200px;
    scroll-behavior: smooth;
}

.query-input{
    flex-grow: 1;
    max-width: 300px;
    min-width:200px;
}

.owner-business-selector {
    flex-grow: 1;
    margin: 10px;
}

@media screen and (max-width: 520px) {
    .selector{
        min-width: 40% !important;
        max-width: unset;
    }
    .query-input{
        min-width: 40% !important;
        max-width: unset;
    }
}

.dark{background-color: #1f1d2b}

.bottom-bar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-radius: 10px 10px 0 0 ;
}

.bottom-bar{
    backdrop-filter: blur(3px);
}

.bottom-bar :nth-child(n){
    margin: auto;
}
.bottom-bar :nth-child(3){
    padding-bottom:24px;
}

.small{
    margin-inline:auto;
    max-width: 1040px !important;
}

main {
    overflow: auto;
    scroll-behavior: smooth;
}

.scrller-container {
    padding-top: 40px;
    overflow-y: auto !important;
    max-height: 100vh !important;
} 

.scroller{
    overflow-y: auto;
    max-height: 100%;
    padding-bottom: 170px;
}
@media screen and (min-width: 720px) {
    .scroller::-webkit-scrollbar {
        max-height: 10px;
        max-width: 10px;
    }
    .scroller::-webkit-scrollbar-track {
        background-color: transparent !important;
        border-radius: 100px;
        /* margin: 10px; */
    }
    .scroller::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: var(--carbonic-1) !important;
        /* box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5); */
    }
    .scroller::-webkit-scrollbar-track {
        /* background-color: #e4e4e4; */
    }
}

/* .scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: linear-gradient(90deg, #ccc, #999);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
} */

.fade-in-top{
    animation: fade-in-top-frame 300ms;
}

.fade-in-card{
    animation: fade-in-card-frame 150ms  cubic-bezier( 0.215, 0.610, 0.355, 1.000);
}
@keyframes fade-in-card-frame {
    0% {
        opacity: .7;
        transform: translateY(5px);
        /* scale: 0.9; */
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        /* scale: 1; */
    }
}
@keyframes fade-in-top-frame {
    0% {
        opacity: 0;
        transform: translateY(-35px);
    }
    50% {
        transform: translateY(0);
    }
    100% {
        opacity: 1;
    }
}
.font-bold {
    font-family: "'Yekan Bakh'";
}
.font-medium {
    font-family: "'Yekan Bakh Medium'";
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.svg-size {
    height: 36px;
    width: 36px;
}
.invoice-top-blur {
    background: linear-gradient(#fff0, #fff 60%);
}
.dark .invoice-top-blur {
    background: linear-gradient(#2d303e00, #2d303e 60%);
}

.record-animation{
    animation: record-frames 1500ms ease-in infinite;
}
@keyframes record-frames {
    0%,100%{
        color: #dd3643;
    }
    50% {
        color: #dd364355;

    }
 
}